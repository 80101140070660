<template>
	<div>

		
		<div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap" >
			<div class="d-flex align-items-center flex-wrap mr-1">

			</div>
			<div class="d-flex align-items-center">
				<b-button variant="success" @click="openAddCat();" style="margin: 0 10px 0 0px;width: 130px;"><i class="la la-plus"></i>{{$t('CATEGORY.MSG_ADD')}}</b-button>
				<b-button v-if="this.disableDraggable" variant="primary" @click="DisableDraggable();" style="width: 130px;"><i class="la la-list"></i>{{$t('CATEGORY.MSG_REORGANIZE')}}</b-button>
				<b-button v-if="!this.disableDraggable" variant="danger" @click="SubmitDraggable();" style="width: 130px;"><i class="la la-save"></i>{{$t('CATEGORY.MSG_SAVE')}}</b-button>
			</div>
			
		</div>
		<div class="row">
			<div class="col-md-12" v-if="hasCategory">
				<b-card>
					
					<table class="table b-table table-hover b-table-no-border-collapse ">
						<thead>
							<tr>
								<th v-for="field in fields" :key="field.key">{{field.label}}</th>
							</tr>
						</thead>
						<draggable v-model="items" tag="tbody" item-key="name" :options="{disabled : disableDraggable, animation: 120}" @end="onEnd">
							<template v-for="row in items">
								<tr :key="row.id_category_product">
									<td scope="row">
										<label class="switch switch-sm">
											<input ref="ref_checkbox_category" type="checkbox" @change="StatusCategory(row.id_category_product,row.status);" :checked="row.status"/>
											<span></span>
										</label>
									</td>
									<td>{{ row.name }}</td>
								</tr>
							</template>
						</draggable>
					</table>
				</b-card>
			</div>
			<div v-else class="col-md-12" >
				<div class="card card-custom card-stretch gutter-b">
					<div class="card-header border-0 pt-5">
						<h3 class="card-title font-weight-bolder">{{$t('CATEGORY.MSG_FIRST_CATEGORY')}}</h3>
						<div class="card-toolbar">
							<div class="dropdown b-dropdown btn-group">
							</div>
						</div>
					</div>
					<div class="card-body d-flex flex-column">
						<div class="flex-grow-1" style="position: relative;">
							<div style="min-height: 178.7px;">
								<div class="apexcharts-canvas apexchartsr2ayx3sv apexcharts-theme-light" style="text-align:center">
									<iframe :src="$t('CATEGORY.MSG_FIRST_CATEGORY_URL')" frameborder="0" allowfullscreen></iframe>
								</div>
							</div>
						</div>
						<div class="pt-1" style="text-align: center;">
							<a href="#" class="btn btn-success btn-shadow-hover font-weight-bolder w-80 py-3" @click="openAddProduct();"><i class="la la-plus"></i>{{$t('CATEGORY.MSG_FIRST_CATEGORY_ADD')}}</a>
						</div>
					</div>
				</div>
			</div>
		</div> 
		<div data-app>
			<v-layout row justify-center>
				<v-dialog v-model="AddCat" fullscreen hide-overlay transition="dialog-bottom-transition">
					<v-card >
						<v-toolbar flat dark color="primary">
							<v-btn icon dark @click="AddCat = false">
								<i class="la la-close"></i>
							</v-btn>
							<v-toolbar-title>{{$t('CATEGORY.MSG_CATEGORY')}}</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-toolbar-items>
								<v-btn success  @click="submitCategory();">{{$t('CATEGORY.MSG_SEND')}}</v-btn>
							</v-toolbar-items>
						</v-toolbar>
						<v-list three-line subheader>
							<b-card class="main-card mb-3">
								<v-layout row wrap>
									<b-row class="col-md-12">
										<label for="input-valid">{{$t('CATEGORY.MSG_NAME')}}</label>
										<b-form-input type="text" v-bind:value="name" v-on:change="name = $event"></b-form-input>
									</b-row>
								</v-layout>
							</b-card>
						</v-list>
					</v-card>
				</v-dialog>
			</v-layout>
		</div>
	</div>
</template>
<script>
import axios from "axios";
//import moment from 'moment'; 
import Swal from "sweetalert2";
import draggable from "vuedraggable";
//moment.locale(sessionStorage.getItem('language'));
/*moment.updateLocale(sessionStorage.getItem('language'), {
    relativeTime : {
        past: function(input) {
          return input === "à l'instant"
            ? input
            : 'depuis '+input
        },
        s  : "à l'instant",
        future: "dans %s",
        ss : '%d secondes',
        m:  "une minute",
        mm: "%d minutes",
        h:  "une heure",
        hh: "%d heures",
        d:  "un jour",
        dd: "%d jours",
        M:  "un mois",
        MM: "%d mois",
        y:  "une année",
        yy: "%d années"
    }
});*/
export default {
	components: {
		draggable
	  },
	data: () => ({
		hasCategory: true,
		dragging: false,
		name : "",
		color : "",
		AddCat : false,
		switch1: false,
		disableDraggable : true,
		fields: [
			{ key: 'status', label: '', sortable: false, 'class': 'text-center' },
			{ key: 'name', label: 'Catégorie', sortable: true, 'class': 'text-center' }
		],
		totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 20, 30],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        items: [],
    
	}),
	mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
		this.getCategory();
		this.fields = [
			{ key: 'status', label: '', sortable: false, 'class': 'text-center' },
			{ key: 'name', label: this.$t('CATEGORY.MSG_CATEGORY'), sortable: true, 'class': 'text-center' }
		];
	},
	methods: {
		onEnd(event) {
			console.log(event);
			console.log(this.items);
		},
		openAddCat() {
			this.AddCat = true;
		},
		DisableDraggable() {
			this.disableDraggable = !this.disableDraggable;
		},
		SubmitDraggable() {
			this.disableDraggable = !this.disableDraggable;
			var arraySubmit = [];
			for(var i in this.items){
				//for(var j in this.items[i]){
					arraySubmit.push({id_category_product : this.items[i].id_category_product , index : i});
				//}
			}
			console.log(arraySubmit);
			var data = {index:arraySubmit};
			axios.post("/klik/category/index",data).then((response) => {
				console.log(response);
				//this.getCategory();
			}).catch((errors) => {    
				console.log(errors)  ;
				localStorage.clear();				
				this.$router.push('/login');
			});
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		getCategory() {
			axios.post("/klik/category/get").then((response) => {
				let str = [];
				if(response.data.Category == false){
					this.hasCategory = false;
					this.items = [];
					this.totalRows = 0;
					return true;
				}
				this.hasCategory = true;
				
				Object.keys(response.data.Category).forEach(function(i) {
					str.push({id_category_product:response.data.Category[i].id_category_product,name:response.data.Category[i].name,status:response.data.Category[i].visible});
				});
				console.log(str);
				this.items = str;
				this.totalRows = str.length;
			}).catch((errors) => {    
				console.log(errors)  ;
				localStorage.clear();				
				this.$router.push('/login');
			});
		},
		submitCategory() {
			var name = this.name;
			if(name == ""){
				Swal.fire({
					title: this.$t("APP_NAME"),
					text: this.$t("CATEGORY.MSG_ALERT_STATUS_TEXT"),
					icon: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					confirmButtonText: "Ok",
				});
				return false;
			}
			var color = this.color;
			var data = {name:name,color:color};
			axios.post("/klik/category/add",data).then((response) => {
				console.log(response);
				this.getCategory();
				this.AddCat = false;
			}).catch((errors) => {    
				this.AddCat = false;
				console.log(errors)  ;
				localStorage.clear();;
				this.$router.push('/login');
			});
		},
		StatusCategory(id_category,status) {
			Swal.fire({
					title: this.$t("CATEGORY.MSG_ALERT_STATUS_TITLE"),
					text: this.$t("CATEGORY.MSG_ALERT_STATUS_TEXT"),
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: "Ok",
					denyButtonText: "Annuler",
			}).then((result) => {
				this.items = [];
				if (result.isConfirmed) {
					status = (status != true ? "t" : "f");
					var data = {id_category:id_category,status:status};
					axios.post("/klik/category/status/change",data).then((response) => {
						console.log(response);
						this.getCategory();
					}).catch((errors) => {    
						this.AddCat = false;
						console.log(errors)   ;
						localStorage.clear();
						this.$router.push('/login');
					});
					
					//return;
				}else{
					Swal.fire(this.$t("APP_NAME"), this.$t("PRODUCT.MSG_ALERT_STATUS_TEXT"), 'info')
					this.getCategory();
				}
			})
			
			
			
			
			/**/
		},
	},
}
</script>
